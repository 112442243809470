import { environment } from "../../../environments/environment";

const apiPath = environment.apiPath;

export const APIConstant = {
    basePath: apiPath,

    quote: `${apiPath}/quoteapp`,
    academicYearSetup: `${apiPath}/quoteapp/academicyear/all`,
    futureAcademicYears: `${apiPath}/quoteapp/academicyear`,
    changeCurrentYear: `${apiPath}/quoteapp/academicyear`,
    copyFromPrevYear: `${apiPath}/quoteapp/copy-from-previous`,
    publisherpreferences: `${apiPath}/quoteapp/preferences/publishers`,
    notebookprefereences: `${apiPath}/quoteapp/preferences/notebooks`,
    pendingPublishersSchool:`${apiPath}/quoteapp/publisherfollowup/schools`,
    pendingPublishers:`${apiPath}/quoteapp/publisherfollowup/pending-selected`,
    actionPublisherAcceptReject:`${apiPath}/quoteapp/publisherfollowup`,
    pendingTextbooksSchool:`${apiPath}/quoteapp/textbookfollowup/schools`,
    pendingTextbooksList:`${apiPath}/quoteapp/textbookfollowup/list`,
    pendingTextbooks:`${apiPath}/quoteapp/textbookfollowup/pending-selected`,
    actionTextbookAcceptReject:`${apiPath}/quoteapp/textbookfollowup`,
    actionTextbookAccept:`${apiPath}/quoteapp/textbookfollowup/approve-action`,
    actionTextbookReject:`${apiPath}/quoteapp/textbookfollowup/reject-action`,
    stationerypreferences: `${apiPath}/quoteapp/preferences/items`,
    preferences: `${apiPath}/quoteapp/preferences`,
    courses:`${apiPath}/quoteapp/courses`,
    subjects:`${apiPath}/quoteapp/subjects`,
    allPreferences:`${apiPath}/quoteapp/preferences/all`,
    textbookDetails:`${apiPath}/quoteapp/textbooks`,
    allTextbookDetails:`${apiPath}/quoteapp/textbooks/group`,
    notebookDetails:`${apiPath}/quoteapp/notebooks/details`,
    stationeryDetails:`${apiPath}/quoteapp/stationeryitems/productdetails`,
    textQuotation:`${apiPath}/quoteapp/text-quotations`,
    notebookQuotation:`${apiPath}/quoteapp/notebook-quotations`,
    listNotebookQuotation:`${apiPath}/quoteapp/notebook-quotations/list`,
    stationeryQuotation:`${apiPath}/quoteapp/stationery-quotations`,
    listStationeryQuotation:`${apiPath}/quoteapp/stationery-quotations/list`,
    allTextbookQuotations:`${apiPath}/quoteapp/text-quotations/all`,
    listTextbookQuotations:`${apiPath}/quoteapp/text-quotations/list`,
    allNotebookQuotations:`${apiPath}/quoteapp/notebook-quotations/all`,
    allStationeryQuotaions:`${apiPath}/quoteapp/stationery-quotations/all`,
    getMoreSubjects:`${apiPath}/quoteapp/more-subjects`,
    noteBookBrands:`${apiPath}/quoteapp/notebooks/brands`,
    stationeryBrands:`${apiPath}/quoteapp/stationeryitems/brands`,
    noteBookSizes:`${apiPath}/quoteapp/notebooks/sizes`,
    noteBookPages:`${apiPath}/quoteapp/notebooks/pages`,
    savingsPerStudent:`${apiPath}/quoteapp/calculation/savings`,
    kitPrice:`${apiPath}/quoteapp/calculation/kitprice`,
    pendingSuppliers:`${apiPath}/quoteapp/ordermanagement/pending-suppliers`,
    supplierDetails:`${apiPath}/quoteapp/ordermanagement/quotations`,
    processedSuppliers:`${apiPath}/quoteapp/ordermanagement/processedorders`,
    orderDetails:`${apiPath}/quoteapp/ordermanagement/order-details`,
    courseStrength:`${apiPath}/quoteapp/strength`,
    courseStrengthList:`${apiPath}/quoteapp/strength/list`,
    placeOrder:`${apiPath}/quoteapp/ordermanagement/place-order`,
    resync:`${apiPath}/quoteapp/resync`,
    academicKitData:`${apiPath}/quoteapp/academickit`,
    academicKitDataSA:`${apiPath}/quoteapp/systemadmin/academic-years`,
    strengthVersions:`${apiPath}/quoteapp/strength/version`,
    versionDetails:`${apiPath}/quoteapp/strength/date`,
    studentsByClassroomId:`${apiPath}/quoteapp/restrictioncodes`,
    strengthFromStudentModule:`${apiPath}/quoteapp/strength/student`,
    restrictedProductList:`${apiPath}/quoteapp/restrictioncodes/products`,
    generateRestrictionCode:`${apiPath}/quoteapp/restrictioncodes/generate`,
    addRestrictionCode:`${apiPath}/quoteapp/restrictioncodes/add`,
    editRestrictionCode:`${apiPath}/quoteapp/restrictioncodes/edit`,
    deleteRestrictionCode:`${apiPath}/quoteapp/restrictioncodes/delete`,
    addRestrictionCodeByClass:`${apiPath}/quoteapp/restrictioncodes/add-byclass`,
    getRestrictionCodeDetails:`${apiPath}/quoteapp/restrictioncodes/list`,
    systemAdminPendingSuppliers:`${apiPath}/quoteapp/systemadmin/pending-suppliers`,
    systemAdminSupplierDetails:`${apiPath}/quoteapp/systemadmin/quotations`,
    systemAdminProcessedOrders:`${apiPath}/quoteapp/systemadmin/processedorders`,
    systemAdminOrderDetails:`${apiPath}/quoteapp/systemadmin/order-details`,
    allSchoolInfo:`${apiPath}/quoteapp/systemadmin/schools`,
    allSchoolInfoPendingOrder:`${apiPath}/quoteapp/systemadmin/pending-schools`,
    allSchoolInfoProcessedOrder:`${apiPath}/quoteapp/systemadmin/process-schools`,
    systemAdminPlaceOrder:`${apiPath}/quoteapp/systemadmin/place-order`,
    distinctSchoolsForAcademicReport:`${apiPath}/quoteapp/systemadmin/academic-schools`,
    sysAdminAcademicReport:`${apiPath}/quoteapp/systemadmin/academic-report`,
    sendSMS:`${apiPath}/quoteapp/restrictioncodes/send-sms`,
    sendWhatsapp:`${apiPath}/quoteapp/restrictioncodes/send-whatsapp`,
    orderSummary:`${apiPath}/quoteapp/ordersummary`,
    orderSummaryV2:`${apiPath}/quoteapp/ordersummary/v2`,
    detailedSummary:`${apiPath}/quoteapp/studentorders`,
    schoolStatus:`${apiPath}/quoteapp/school-status`,
    allGradeSKUDetails:`${apiPath}/quoteapp/gradesku`,
    gradeSKUDetails:`${apiPath}/quoteapp/gradesku/byid`,
    systemAdminOrderedSchoolDetails:`${apiPath}/quoteapp/systemadmin/ordered-schools`,
    systemAdminOrderedSchoolWiseDetails:`${apiPath}/quoteapp/systemadmin/ordered-school-details`,
    systemAdminCustomerSummary:`${apiPath}/quoteapp/systemadmin/customer-summary`,
    systemAdminCustomerwiseDetailedReport:`${apiPath}/quoteapp/systemadmin/customer-details`,
    postMilestone:`${apiPath}/quoteapp/sysadmin/milestones`,
    getMilesotne: `${apiPath}/quoteapp/sysadmin/notification`,
    getAllNotification:`${apiPath}/quoteapp/sysadmin/notification/milestone`,
    taskDefinition: `${apiPath}/quoteapp/sysadmin/taskdef`,
    allSchool: `${apiPath}/quoteapp/sysadmin/schoolInfo/all`,
    getSchool: `${apiPath}/quoteapp/sysadmin/schoolInfo`,
    createSchool: `${apiPath}/quoteapp/sysadmin/schoolInfo/create-schoolInfo`,
    getSchoolByCode: `${apiPath}/quoteapp/sysadmin/schoolInfo/onlineStoreDataBySchoolCode`,
    allMilestoneStatus: `${apiPath}/quoteapp/sysadmin/milestones/all`,
    protendTasks: `${apiPath}/progresspath/taskdefinition/prontedTasks/byAssociatedSchoolCode`,
    schoolTasks: `${apiPath}/progresspath/taskdefinition/schoolTasks/byAssociatedSchoolCode`,
    generateDocument : `${apiPath}/quoteapp/sysadmin/schoolInfo/agreement`,
    academicYears: `${apiPath}/quoteapp/academicyear/all`

};

export const PublicAPI = [];
