import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { CRUDService, BaseService, CommonUtility } from "projects/common/src/public-api";
import { APIConstant, Catergory } from "../constant";
import { catchError, mergeMap } from "rxjs/operators";
import { CommonConstant } from "@school-core";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root",
})
export class QuoteService extends CRUDService<any> {
    constructor(protected baseService: BaseService, private http: HttpClient) {
        super(baseService, APIConstant.quote + "/");
    }

    getAcademicYear(): Observable<any[]>{
        return this.baseService.get(`${APIConstant.academicYearSetup}`);
    }
    getAcademicYears(): Observable<any[]>{
        return this.baseService.get(`${APIConstant.academicYears}`)
    }
    
    getFutureAcademicYears(): Observable<any[]>{
        return this.baseService.get(`${APIConstant.academicYearSetup}`);
    }

    changeCurrentYear(data:any): Observable<any[]>{
        return this.baseService.put(`${APIConstant.changeCurrentYear}`,data);
    }

    copyFromPrevYear(academicYear:any): Observable<any[]>{
        return this.baseService.post(`${APIConstant.copyFromPrevYear}?academicYear=${academicYear}`,{});
    }

    add(data: any): Observable<any> {
        return this.baseService.post<any>(APIConstant.futureAcademicYears, data);
    }

    update(id: number, data: any): Observable<any> {
        let url: string = APIConstant.futureAcademicYears;
        if (id) {
            url += `${id}`;
        }
        return this.baseService.put<any>(url, data);
    }

    getPublishers(): Observable<any[]> {
        return this.baseService.get(`${APIConstant.publisherpreferences}`);
    }

    getNoteBooksRulingTypes(): Observable<any> {
        return this.baseService.get(`${APIConstant.notebookprefereences}`);
    }

    getstationeryItems(): Observable<any> {
        return this.baseService.get(`${APIConstant.stationerypreferences}`);
    }

    addPreferences(preferences, category): Observable<any> {
        const request = {
            preferences: preferences,
        }
        return this.baseService.post(`${APIConstant.preferences}?category=` + category, preferences);
    }

    getCourses() {
        return this.baseService.get(`${APIConstant.courses}`);
    }

    getSubjects(classroomId) {
        return this.baseService.get(`${APIConstant.subjects}` + "?classroomId=" + classroomId);
    }

    getPreferedPublishers(catergory) {
        return this.baseService.get(`${APIConstant.allPreferences}` + "?category=" + catergory);
    }

    getPendingPublishersSchool() {
        return this.baseService.get(`${APIConstant.pendingPublishersSchool}`);
    }

    addPublisher(pendingObj) {
        return this.baseService.post(`${APIConstant.actionPublisherAcceptReject}`,pendingObj);
    }

    updatePublisherAcceptReject(pendingObj) {
        return this.baseService.put(`${APIConstant.actionPublisherAcceptReject}`,pendingObj);
    }

    getPendingPublishers(data,schools) {
        const query = CommonUtility.convertObjectToParams(data);
        return this.baseService.post(`${APIConstant.pendingPublishers}?${query}`,schools);
    }

    getPendingTextBooksSchool() {
        return this.baseService.get(`${APIConstant.pendingTextbooksSchool}`);
    }

    addPendingTextBooksSchool(pendingObj) {
        return this.baseService.post(`${APIConstant.pendingTextbooksList}`,pendingObj);
    }

    addTextBooksAccept(pendingObj) {
        return this.baseService.post(`${APIConstant.actionTextbookAcceptReject}`,pendingObj);
    }

    updateTextBooksAccept(textbookFollowupId,pendingObj) {
        return this.baseService.put(`${APIConstant.actionTextbookAccept}?textbookFollowupId=${textbookFollowupId}`,pendingObj);
    }

    updateTextBooksReject(textbookFollowupId) {
        return this.baseService.put(`${APIConstant.actionTextbookReject}?textbookFollowupId=${textbookFollowupId}`,null);
    }

    getPendingTextBooks(data,schools) {
        const query = CommonUtility.convertObjectToParams(data);
        return this.baseService.post(`${APIConstant.pendingTextbooks}?${query}`,schools);
    }

    getTextbookDetails(courseName, subjectName, selectedPublisher) {
        const request = {
            className: courseName,
            subjectName: subjectName,
            publisherName: selectedPublisher,
        }
        const queryParams = CommonUtility.convertObjectToParams(request);
        return this.baseService.get(`${APIConstant.textbookDetails}?${queryParams}`);
    }

    getAllTextbookDetails(subjectName, selectedPublisher) {
        const request = {
            subjectName: subjectName,
            publisherName: selectedPublisher,
        }
        const queryParams = CommonUtility.convertObjectToParams(request);

        return this.baseService.get(`${APIConstant.allTextbookDetails}?${queryParams}`);
    }


    addTextBookQuotation(textbookQuotation) {
        return this.baseService.post(`${APIConstant.textQuotation}`, textbookQuotation);
    }

    addTextBookQuotations(textbookQuotations) {
        return this.baseService.post(`${APIConstant.listTextbookQuotations}`, textbookQuotations);
    }

    updateTextBookQuotation(textbookQuotation) {
        return this.baseService.put(`${APIConstant.textQuotation}`, textbookQuotation);
    }

    addNoteBookQuotation(notebookQuotation) {
        return this.baseService.post(`${APIConstant.notebookQuotation}`, notebookQuotation);
    }

    addNoteBookQuotations(notebookQuotations) {
        return this.baseService.post(`${APIConstant.listNotebookQuotation}`, notebookQuotations);
    }


    updateNoteBookQuotation(notebookQuotation) {
        return this.baseService.put(`${APIConstant.notebookQuotation}`, notebookQuotation);
    }

    addStationeryQuotation(staioneryQuotation) {
        return this.baseService.post(`${APIConstant.stationeryQuotation}`, staioneryQuotation);
    }

    addStationeryQuotations(staioneryQuotations) {
        return this.baseService.post(`${APIConstant.listStationeryQuotation}`, staioneryQuotations);
    }

    updateStationeryQuotation(staioneryQuotation) {
        return this.baseService.put(`${APIConstant.stationeryQuotation}`, staioneryQuotation);
    }

    getAllTextbookQuotations(courseId) {
        const request = {
            courseId: courseId,
        }
        const queryParams = CommonUtility.convertObjectToParams(request);
        return this.baseService.get(`${APIConstant.allTextbookQuotations}?${queryParams}`);
    }

    getAllNotebookQuotations(courseId) {
        const request = {
            courseId: courseId,
        }
        const queryParams = CommonUtility.convertObjectToParams(request);
        return this.baseService.get(`${APIConstant.allNotebookQuotations}?${queryParams}`);
    }

    getAllStationeryQuotations(courseId) {
        const request = {
            courseId: courseId,
        }
        const queryParams = CommonUtility.convertObjectToParams(request);
        return this.baseService.get(`${APIConstant.allStationeryQuotaions}?${queryParams}`);
    }

    getMoreSubjects() {
        return this.baseService.get(`${APIConstant.getMoreSubjects}`);
    }

    getPreferencesByCategory(category) {
        return this.baseService.get(`${APIConstant.preferences}` + "?category=" + category);
    }

    getAllPreferencesByCategory(category) {
        return this.baseService.get(`${APIConstant.allPreferences}` + "?category=" + category);
    }

    getNotebookBrands() {
        return this.baseService.get(`${APIConstant.noteBookBrands}`);
    }

    getNotebookSizes() {
        return this.baseService.get(`${APIConstant.noteBookSizes}`);
    }

    getNotebooksPageCount() {
        return this.baseService.get(`${APIConstant.noteBookPages}`);
    }

    getAllNoteBookDetails(selectedPageCount, selectedRuling, selectedSize, selectedBrand) {
        const request = {
            brandName: selectedBrand,
            rulingType: selectedRuling,
            size: selectedSize,
            pages: selectedPageCount
        }
        const queryParams = CommonUtility.convertObjectToParams(request);
        return this.baseService.get(`${APIConstant.notebookDetails}?${queryParams}`);
    }

    getAllStationeryDetails(selectedItemType, brand) {
        const request = {
            itemType: selectedItemType,
            brandName: brand,
        }
        const queryParams = CommonUtility.convertObjectToParams(request);
        return this.baseService.get(`${APIConstant.stationeryDetails}?${queryParams}`);
    }

    getStationeryBrands() {
        return this.baseService.get(`${APIConstant.stationeryBrands}`);
    }

    savingsPerStudent(courseId) {
        return this.baseService.get(`${APIConstant.savingsPerStudent}` + "?courseId=" + courseId);
    }

    kitPrice(courseId) {
        return this.baseService.get(`${APIConstant.kitPrice}` + "?courseId=" + courseId);
    }

    deleteTextbookQuotation(textQuotation) {
        return this.baseService.delete(`${APIConstant.textQuotation}` + "?quotationId=" + textQuotation.id);
    }

    deleteNotebookQuotation(noteBookQuotation) {
        return this.baseService.delete(`${APIConstant.notebookQuotation}` + "?quotationId=" + noteBookQuotation.id);
    }

    deleteStationeryQuotation(stationeryQuotation) {
        return this.baseService.delete(`${APIConstant.stationeryQuotation}` + "?quotationId=" + stationeryQuotation.id);
    }

    getPendingSuppliers(productType) {
        return this.baseService.get(`${APIConstant.pendingSuppliers}` + "?category=" + productType);
    }

    getProcessedDetails(productType) {
        return this.baseService.get(`${APIConstant.processedSuppliers}` + "?category=" + productType);
    }

    getQuotationsbySupplier(productType, supplier) {
        const request = {
            category: productType,
            supplierName: supplier.supplierName,
            publisherName: supplier.publisherName,
        }
        const query = CommonUtility.convertObjectToParams(request);
        return this.baseService.get(`${APIConstant.supplierDetails}?${query}`);
    }

    getOrderedQuotationsbySupplier(productType, orderId) {
        return this.baseService.get(`${APIConstant.orderDetails}` + "?category=" + productType + "&orderId=" + orderId);
    }

    getCourseStrength() {
        return this.baseService.get(`${APIConstant.courseStrength}`);
    }

    getCourseStrengthByCourseId(courseId) {
        return this.baseService.get(`${APIConstant.courseStrength}` + "/id?courseId=" + courseId);
    }

    addCourseStrength(request) {
        return this.baseService.post(`${APIConstant.courseStrengthList}`, request);
    }

    placeOrder(request, productType) {
        return this.baseService.post(`${APIConstant.placeOrder}` + "?category=" + productType, request);
    }

    resync() {
        return this.baseService.put(`${APIConstant.resync}`, {});
    }

    getAcademicKitData(academicYear) {
        return this.baseService.get(`${APIConstant.academicKitData}?academicYear=${academicYear}`);
    }
    getAcademicKitDataForSa() {
        return this.baseService.get(`${APIConstant.academicKitDataSA}`);
    }

    getAcademicKitDataForSchool(schoolCd) {
        return this.baseService.get(`${APIConstant.academicKitDataSA}?schoolCd=${schoolCd}`);
    }

    getStrengthVersions() {
        return this.baseService.get(`${APIConstant.strengthVersions}`);
    }


    getStrengthVersionsDetails(date) {
        const request = {
            date: date
        }
        const queryParams = CommonUtility.convertObjectToParams(request);
        return this.baseService.get(`${APIConstant.versionDetails}?${queryParams}`);
    }

    getStudentsByClassroom(classroomId) {
        return this.baseService.get(`${APIConstant.studentsByClassroomId}` + "?classroomId=" + classroomId);
    }

    getStudentStrengthFromStudentModule() {
        return this.baseService.get(`${APIConstant.strengthFromStudentModule}`);
    }

    getRestrictedProductList() {
        return this.baseService.get(`${APIConstant.restrictedProductList}`);
    }

    generateRestrictionCode(studentProfileId, useAdmissionNumber) {
        return this.baseService.post(`${APIConstant.generateRestrictionCode}` + "?studentId=" + studentProfileId + "&useAdmissionNumber=" + useAdmissionNumber, "");
    }

    addRestrictionCode(request) {
        return this.baseService.post(`${APIConstant.addRestrictionCode}`, request);
    }

    editRestrictionCode(request) {
        return this.baseService.put(`${APIConstant.editRestrictionCode}`, request);
    }

    deleteRestrictionCode(restrictionCodeId) {
        return this.baseService.delete(`${APIConstant.deleteRestrictionCode}` + "?codeId=" + restrictionCodeId);
    }

    addRestrictionCodeByClass(request, useAdmissionNumber) {
        return this.baseService.post(`${APIConstant.addRestrictionCodeByClass}` + "?useAdmissionNumber=" + useAdmissionNumber, request);
    }

    getRestrictionCodeDetails(classroomId) {
        return this.baseService.get(`${APIConstant.getRestrictionCodeDetails}` + "?classroomId=" + classroomId);
    }

    getSystemAdminPendingSuppliers(productType, request) {
        return this.baseService.post(`${APIConstant.systemAdminPendingSuppliers}` + "?category=" + productType, request);
    }

    getSystemAdminProcessedDetails(productType, request) {
        return this.baseService.post(`${APIConstant.systemAdminProcessedOrders}` + "?category=" + productType, request);
    }

    getSystemAdminQuotationsbySupplier(productType, supplier, request) {
        return this.baseService.post(`${APIConstant.systemAdminSupplierDetails}` + "?category=" + productType + "&supplierName=" + supplier, request);
    }

    getAllSchoolsInfoPendingOrder(productType) {
        return this.baseService.post(`${APIConstant.allSchoolInfoPendingOrder}` + "?category=" + productType, {});
    }

    getAllSchoolsInfoProcessedOrder(productType) {
        return this.baseService.post(`${APIConstant.allSchoolInfoProcessedOrder}` + "?category=" + productType, {});
    }


    getSystemAdminOrderDetails(productType, orderId, request) {
        return this.baseService.post(`${APIConstant.systemAdminOrderDetails}` + "?category=" + productType + "&orderId=" + orderId, request);
    }

    systemAdminPlaceOrder(request, productType) {
        return this.baseService.post(`${APIConstant.systemAdminPlaceOrder}` + "?category=" + productType, request);
    }

    getDistinctSchoolsForAcademicReport() {
        return this.baseService.get(`${APIConstant.distinctSchoolsForAcademicReport}`);
    }

    getAcademicReportForSysAdmin(request,academicYear) {
        return this.baseService.post(`${APIConstant.sysAdminAcademicReport}` + "?academicYear="+academicYear, request);
    }

    sendSMS(request) {
        return this.baseService.post(`${APIConstant.sendSMS}`, request);
    }

    sendWhatsappNotification(request) {
        return this.baseService.post(`${APIConstant.sendWhatsapp}`, request);
    }

    getOrderSummary(showSkuWise) {
        if (showSkuWise) {
            return this.baseService.get(`${APIConstant.orderSummary}` + "?showBySku=true");
        }
        return this.baseService.get(`${APIConstant.orderSummary}`);
    }

    getOrderSummaryV2() {
        return this.baseService.get(`${APIConstant.orderSummaryV2}`);
    }

    getDetailedSummary() {
        return this.baseService.get(`${APIConstant.detailedSummary}`);
    }

    getSchoolStatus() {
        return this.baseService.get(`${APIConstant.schoolStatus}`);
    }

    addSchoolStatus(request) {
        return this.baseService.post(`${APIConstant.schoolStatus}`, request);
    }

    getAllGradeSKUDetails() {
        return this.baseService.get(`${APIConstant.allGradeSKUDetails}`);
    }

    getGradeSKUDetails(bundleId) {
        const queryParams = CommonUtility.convertObjectToParams(bundleId);
        return this.baseService.get(`${APIConstant.gradeSKUDetails}?${queryParams}`);
    }

    updateGradeSKUDetails(request) {
        const queryParams = CommonUtility.convertObjectToParams(request);
        return this.baseService.put(`${APIConstant.allGradeSKUDetails}`, queryParams);
    }

    addGradeSKUDetails(request) {
        const queryParams = CommonUtility.convertObjectToParams(request);
        return this.baseService.post(`${APIConstant.allGradeSKUDetails}`, request);
    }

    deleteGradeSKUDetails(id) {
        console.log('id' + id)
        const queryParams = CommonUtility.convertObjectToParams({ bundleId: id });
        return this.baseService.delete(`${APIConstant.allGradeSKUDetails}?${queryParams}`);
    }

    getOrderedSchoolDetailsInfo() {
        return this.baseService.get(`${APIConstant.systemAdminOrderedSchoolDetails}`);
    }

    getOrderedSchoolWiseDetails(optionId) {
        const queryParams = CommonUtility.convertObjectToParams({ optionId: optionId });
        return this.baseService.get(`${APIConstant.systemAdminOrderedSchoolWiseDetails}?${queryParams}`);
    }

    getCustomerwiseSummaryReport(optionId) {
        const queryParams = CommonUtility.convertObjectToParams({ optionId: optionId });
        return this.baseService.get(`${APIConstant.systemAdminCustomerSummary}?${queryParams}`);
    }

    getCustomerwiseDetailedReport(optionId) {
        const queryParams = CommonUtility.convertObjectToParams({ optionId: optionId });
        return this.baseService.get(`${APIConstant.systemAdminCustomerwiseDetailedReport}?${queryParams}`);
    }

    postMileStone(data:any){
        return this.baseService.post(`${APIConstant.postMilestone}`,data)
    }

    updateMileStone(data:any){
        return this.baseService.put(`${APIConstant.postMilestone}`,data)
    }

    getMileStone(){
        return this.baseService.get(`${APIConstant.postMilestone}/all`)
    }
    deleteMileStone(data:number){
          return this.baseService.delete(`${APIConstant.postMilestone}?id=${data}`)
    }
    
    deleteNotification(data:any){
       return this.baseService.delete(`${APIConstant.getMilesotne}?id=${data}`)
    }

   addNotification(data:any){
        return this.baseService.post(`${APIConstant.getMilesotne}`,data)
    }
    updateNotification(data:any){
        return this.baseService.put(`${APIConstant.getMilesotne}`,data)
    }

    getAllNotifications(data: any){
        return this.baseService.get(`${APIConstant.getAllNotification}?milestoneId=${data}`)
    }

    addTask(data:any){
        return this.baseService.post(`${APIConstant.taskDefinition}`,data)
    }
    updateTask(data:any){
        return this.baseService.put(`${APIConstant.taskDefinition}`,data)
    }
    getAllTasks(data:any){
       return this.baseService.get(`${APIConstant.taskDefinition}/milestone?milestoneId=${data}`)
    }
    deleteTask(data:any){
        return this.baseService.delete(`${APIConstant.taskDefinition}?id=${data}`)
    }

    changeOrder(fromOrder:number, to:number): Observable<any>{

        const params = CommonUtility.convertObjectToParams({
            fromOrder: fromOrder,
            toOrder: to,
        });
        return this.baseService.put(`${APIConstant.postMilestone}/changeOrder?${params}`,null)
    }
  
    getAllSchoolInfo() {
        const headers = {};
        headers["Authorization"] = `${window.sessionStorage.getItem(CommonConstant.token)}`;
        headers['SCHOOL_CODE'] = `${window.sessionStorage.getItem(CommonConstant.orgCode)}`;
        headers['EMPLOYEE_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.employeeId)}`;
        headers['Accept'] = '*/*';
        if ((`${window.sessionStorage.getItem(CommonConstant.appName)}` === 'SmartParent')) {
            headers['APP_NAME'] = 'SmartParent';
        } else {
            headers['APP_NAME'] = 'Spectrum';
        }

        return this.http.get(`${APIConstant.allSchool}`, { headers: headers });
    }

    addSchool(data: any) {
        const headers = {};
        headers["Authorization"] = `${window.sessionStorage.getItem(CommonConstant.token)}`;
        headers['SCHOOL_CODE'] = `${window.sessionStorage.getItem(CommonConstant.orgCode)}`;
        headers['EMPLOYEE_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.employeeId)}`;
        headers['Accept'] = '*/*';
        if ((`${window.sessionStorage.getItem(CommonConstant.appName)}` === 'SmartParent')) {
            headers['APP_NAME'] = 'SmartParent';
        } else {
            headers['APP_NAME'] = 'Spectrum';
        }

        return this.http.post<any>(`${APIConstant.createSchool}`, data, { headers: headers });
    }

    getAllSchoolInfoByName(searchStr) {
        const headers = {};
        headers["Authorization"] = `${window.sessionStorage.getItem(CommonConstant.token)}`;
        headers['SCHOOL_CODE'] = `${window.sessionStorage.getItem(CommonConstant.orgCode)}`;
        headers['EMPLOYEE_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.employeeId)}`;
        headers['Accept'] = '*/*';
        if ((`${window.sessionStorage.getItem(CommonConstant.appName)}` === 'SmartParent')) {
            headers['APP_NAME'] = 'SmartParent';
        } else {
            headers['APP_NAME'] = 'Spectrum';
        }

        return this.http.get(`${APIConstant.getSchool}/search?schoolNameSearchString=${searchStr}`, { headers: headers });
    }

    basicInfo(schoolCd: number): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.getSchoolByCode}?schoolCd=${schoolCd}`);
    }

    getMilestoneStatus(): Observable<any> {
        return this.baseService.get<any>(`${APIConstant.allMilestoneStatus}`);
    }

    updateMileStoneStatus(data) {
        const headers = {};
        headers["Authorization"] = `${window.sessionStorage.getItem(CommonConstant.token)}`;
        headers['SCHOOL_CODE'] = `${window.sessionStorage.getItem(CommonConstant.orgCode)}`;
        headers['EMPLOYEE_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.employeeId)}`;
        headers['Accept'] = '*/*';
        if ((`${window.sessionStorage.getItem(CommonConstant.appName)}` === 'SmartParent')) {
            headers['APP_NAME'] = 'SmartParent';
        } else {
            headers['APP_NAME'] = 'Spectrum';
        }

        return this.http.put(`${APIConstant.getSchool}/`, data, { headers: headers });
    }

    updateSchool(data) {
        const headers = {};
        headers["Authorization"] = `${window.sessionStorage.getItem(CommonConstant.token)}`;
        headers['SCHOOL_CODE'] = `${window.sessionStorage.getItem(CommonConstant.orgCode)}`;
        headers['EMPLOYEE_PROFILE_ID'] = `${window.sessionStorage.getItem(CommonConstant.employeeId)}`;
        headers['Accept'] = '*/*';
        if ((`${window.sessionStorage.getItem(CommonConstant.appName)}` === 'SmartParent')) {
            headers['APP_NAME'] = 'SmartParent';
        } else {
            headers['APP_NAME'] = 'Spectrum';
        }
        return this.http.put(`${APIConstant.getSchool}/`, data, { headers: headers });
    }

    getProtendTasks(schoolCd) {
        return this.baseService.get<any>(`${APIConstant.protendTasks}?associatedSchoolCode=${schoolCd}`);
    }

    getSchoolTasks(schoolCd) {
        return this.baseService.get<any>(`${APIConstant.schoolTasks}?associatedSchoolCode=${schoolCd}`);
    }

    generateDocument(schoolCd)
    {   
        const headers = new Headers();
        headers.set('Content-Type', 'text/html; charset=UTF-8');
        
        return this.baseService.getWithResponseType<string>(`${APIConstant.generateDocument}?schoolCd=${schoolCd}`, "text");
    }
}
